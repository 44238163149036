import React from 'react';

import ImageProxy from '@next-image-proxy';

import { DeserializedUser } from '@api';

import { User } from '@ui-uikit/lib/components/icons/mono';
import { cn } from '@ui-uikit/lib/utils';

import { getMembershipClassNames } from './utils';

interface UserAvatarProps {
  user?: DeserializedUser;
  className?: string;
}

const DEFAULT_AVATAR_PLACEHOLDER_HASH = '366a4ca5-cab7-404a-9ee9-4b79e56bc3cd';

const UserAvatar = ({ user, className }: UserAvatarProps) => {
  const membershipClassName = user?.membershipTier?.attributes
    ? getMembershipClassNames(user.membershipTier.attributes.key).avatar
    : '';
  const hasAvatar =
    !!user?.attributes?.icon && user?.attributes?.icon !== DEFAULT_AVATAR_PLACEHOLDER_HASH;

  return (
    <span className={cn('msq-avatar', membershipClassName, className)}>
      {hasAvatar && (
        <ImageProxy
          width={96}
          height={96}
          className="msq-avatar-img"
          src={user.attributes.icon}
          alt="My Avatar"
        />
      )}

      {!hasAvatar && (
        <span className="msq-avatar-img">
          <User className="msq-avatar-icon" />
        </span>
      )}
    </span>
  );
};

export default UserAvatar;
