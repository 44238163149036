import { MEMBERSHIP_TIER_ID, MembershipTierId } from '@shared/api/types/membership';

export const getMembershipClassNames = (membership: MembershipTierId) => {
  return {
    [MEMBERSHIP_TIER_ID.FREE]: {
      border: '',
      text: '',
      bg: '',
      avatar: '',
    },
    [MEMBERSHIP_TIER_ID.PLUS]: {
      avatar: 'msq-avatar-border msq-avatar-border-plus',
      border: 'border-membership-plus',
      text: 'text-membership-plus',
      bg: 'bg-membership-plus',
    },
    [MEMBERSHIP_TIER_ID.PREMIUM]: {
      avatar: 'msq-avatar-border msq-avatar-border-premium',
      border: 'border-membership-premium',
      text: 'text-membership-premium',
      bg: 'bg-membership-premium',
    },
    [MEMBERSHIP_TIER_ID.PREMIUM_PLUS]: {
      avatar: 'msq-avatar-border msq-avatar-border-premium-plus',
      border: 'border-membership-premium-plus',
      text: 'text-membership-premium-plus',
      bg: 'bg-membership-premium-plus',
    },
  }[membership];
};

export const getMembershipName = (membership: MembershipTierId) => {
  return {
    [MEMBERSHIP_TIER_ID.FREE]: 'Basic',
    [MEMBERSHIP_TIER_ID.PLUS]: 'Plus',
    [MEMBERSHIP_TIER_ID.PREMIUM]: 'Premium',
    [MEMBERSHIP_TIER_ID.PREMIUM_PLUS]: 'Premium Plus',
  }[membership];
};
